import * as React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Meta from '../components/Meta'
import Layout from '../components/Layout'
import Masonry from '../components/Masonry'

function Page({ location }) {
  const { shareImage } = useStaticQuery(graphql`
    query {
      shareImage: file(
        relativePath: { eq: "services/artificial-turf-banner-1.webp" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  return (
    <>
      <Meta
        title="Artificial Turf"
        description="We supply and fit artificial grass across Berkshire and Surrey. Choose a turf that suits your taste. Our artificial turf supplier offers a 10 year guarantee."
        location={location}
        image={shareImage}
      />
      <Layout>
        <section className="lg:flex justify-between bg-gray-lightest border-b-8 border-green">
          <div className="flex-1 max-w-7xl">
            <StaticImage
              src="../images/services/artificial-turf-banner-1.webp"
              alt="Featured turfing work"
              className="aspect-w-16 aspect-h-7"
              aspectRatio={16 / 7}
              layout="fullWidth"
              placeholder="blurred"
              transformOptions={{ fit: 'inside' }}
            />
          </div>
          <div className="grid justify-center content-center mx-auto py-12 px-8 lg:py-16 lg:px-12">
            <h2 className="uppercase text-4xl font-extrabold sm:text-5xl">
              Artificial turf
            </h2>
          </div>
        </section>
        <section className="bg-white overflow-hidden">
          <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
            <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
              <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray">
                Suppliers and Installers of Artificial Grass
              </h3>
            </div>
            <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="mt-8 lg:mt-0">
                <div className="text-base max-w-prose mx-auto lg:max-w-none">
                  <p className="text-lg text-gray-light">
                    Some people have gardens or lifestyles that just suit
                    artificial grass. It&apos;s extremely low maintenance, and
                    these days, artificial turf can often look indistinguishable
                    to regular grass. We supply and fit artificial grass across
                    Berkshire and Surrey. We offer a range of samples to allow
                    our clients to choose a turf that suits their taste. Our
                    artificial turf supplier offers a 10 year guarantee, which
                    will give you peace of mind knowing that you are receiving a
                    quality product. If you are interested in getting artificial
                    grass for your garden, we would welcome any questions you
                    may have about the product and the installation process.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="max-w-7xl mx-auto mb-16">
          <Masonry minWidth={350}>
            <StaticImage
              src="../images/services/artificial-turf-feature-1.jpg"
              alt="Featured work"
              aspectRatio={4 / 3}
              transformOptions={{ fit: 'inside' }}
              placeholder="blurred"
            />
            <StaticImage
              src="../images/services/artificial-turf-feature-2.jpg"
              alt="Featured work"
              aspectRatio={4 / 3}
              transformOptions={{ fit: 'inside' }}
              placeholder="blurred"
            />
            <StaticImage
              src="../images/services/artificial-turf-feature-3.jpg"
              alt="Featured work"
              aspectRatio={4 / 3}
              transformOptions={{ fit: 'inside' }}
              placeholder="blurred"
            />
            <StaticImage
              src="../images/services/artificial-turf-feature-4.jpg"
              alt="Featured work"
              aspectRatio={16 / 9}
              transformOptions={{ fit: 'inside' }}
              placeholder="blurred"
            />
            <StaticImage
              src="../images/services/artificial-turf-feature-5.jpg"
              alt="Featured work"
              aspectRatio={16 / 9}
              transformOptions={{ fit: 'inside' }}
              placeholder="blurred"
            />
          </Masonry>
        </section>
      </Layout>
    </>
  )
}

Page.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

export default Page
